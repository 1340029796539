import React, { memo, useRef } from "react";
import { Dialog } from "primereact/dialog";

import { ButtonAdd, ButtonDelete } from "../../assets";
import {
  Edge,
  EdgeChange,
  EdgeProps,
  getOutgoers,
  Handle,
  MarkerType,
  NodeProps,
  Position
} from "react-flow-renderer";
import {
  addNode,
  addRobotInteraction,
  addUserInteractions,
  changeLabel,
  deleteUserInteraction,
  onNodesChange,
  organizeNodes,
  setLevelUntiedNodes,
  setLevels,
  updateRobotInteraction,
  updateUserInteraction
} from "../../redux/slices/nodes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { onEdgesChange } from "../../redux/slices/edges";
import { Toast } from "primereact/toast";
import { IUserInteraction, NodeData } from "./NodeData";
import { changedPhoneComponent } from "../../redux/slices/flowupdates";
import { setVariablesIsUsedOnAnotherNodeModalOpen } from "../../redux/slices/flowInteractions";
import { ISlateText } from "../../util/deserializeOnLoad";
import { Button } from "primereact/button";
import { getBestPosition } from "../../util/getBestPosition";
import { v4 as uuidv4 } from "uuid";
import { entityIsSetAsVariable } from "../../util/entityIsUsedAsVariable";
import { useLocation, useParams } from "react-router-dom";

export default memo(({ data, isConnectable, selected, ...rest }: NodeProps) => {
  const showButtons = selected;
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const edges = useAppSelector((state) => state.edges);
  const nodes = useAppSelector((state) => state.nodes);
  const NodeIsUsedAsVariableModal = useAppSelector(
    (state) => state.flowInteractions.nodeIsUsedAsVariableModal
  );
  const nodeSelected = nodes?.filter((node) => node.selected === true)[0];
  const toast = useRef<Toast>(null);
  const toastError = useRef<Toast>(null);
  const nodeSelectedHasTargetSetOnFirstUserInteraction =
    nodeSelected &&
    nodeSelected?.data &&
    nodeSelected?.data.userInteractions &&
    nodeSelected?.data.userInteractions[0] &&
    nodeSelected?.data.userInteractions[0].target?.length;

  const addNodeDefault = async () => {
    const newNodeId = uuidv4();
    const oldNodeId = nodeSelected?.id;
    const oldNodeUserInteractionId =
      nodeSelected &&
      nodeSelected?.data &&
      nodeSelected?.data?.userInteractions &&
      (nodeSelected?.data?.userInteractions[0]?.id as string);
    const newUserInteractionsOldNode: IUserInteraction = {
      id: (Math.random() * 907979).toString(),
      source: nodeSelected?.id,
      target: newNodeId,
      comparison: "Igual a",
      phrases: [],
      entityTypeId: -1,
      entityTypeName: ""
    };
    const NodeIncrement = checkSameName(1);
    const userInteractionsNewNode = [
      {
        id: (Math.random() * 907979).toString(),
        source: newNodeId,
        target: "",
        comparison: "Igual a",
        phrases: [],
        text: "",
        entityTypeId: -1,
        entityTypeName: ""
      }
    ];

    const NewNode = {
      id: newNodeId,
      draggable: true,
      position: {
        x: rest.xPos,
        y: rest.yPos
      },
      selectable: true,
      selected: false,
      type: "defaultNode",
      data: {
        label: `${NodeIncrement}-Inserir-título`,
        botInteractions: [],
        userInteractions: userInteractionsNewNode,
        entityTypeId: -1,
        entityTypeName: "",
        sourceEntityNodeId: "",
        level: data?.level + 1
      }
    };
    const NewPosition = getBestPosition({ nodes, edges, node: nodeSelected });
    NewNode.position.x = NewPosition.x;
    NewNode.position.y = NewPosition.y;
    // dispatch(unSelectAll());
    const countOutgoers = getOutgoers(nodeSelected, nodes, edges);
    if (
      countOutgoers?.length === 0 &&
      !nodeSelectedHasTargetSetOnFirstUserInteraction
    ) {
      const objectUpdate = {
        id: oldNodeUserInteractionId as string,
        source: oldNodeId,
        target: newNodeId,
        comparison: "Igual a",
        phrases: nodeSelected?.data.userInteractions[0].phrases,
        entityTypeName: nodeSelected?.data.userInteractions[0].entityTypeName,
        entityTypeId: nodeSelected?.data.userInteractions[0].entityTypeId,
        nodeId: oldNodeId,
        userInteractionId: oldNodeUserInteractionId as string
      };
      dispatch(updateUserInteraction(objectUpdate));
    } else {
      dispatch(addUserInteractions(newUserInteractionsOldNode));
    }
    dispatch(addNode({ node: NewNode }));
    dispatch(changedPhoneComponent(true));
    dispatch(setLevels(edges));
    const newEdge = {
      id: `reactflow__edge-${oldNodeId}a-${newNodeId}`,
      source: oldNodeId,
      target: newNodeId,
      animated: false,
      style: {
        stroke: "rgba(217, 217, 217, 1)",
        strokeWidth: "2px"
      },
      markerEnd: {
        type: "arrowclosed" as MarkerType
      }
    };
    dispatch(onEdgesChange([{ item: newEdge, type: "add" }]));
    dispatch(addRobotInteraction({ nodeId: newNodeId }));
    dispatch(setLevels(edges));
    dispatch(changedPhoneComponent(true));
  };

  const checkSameName = (count: number): number => {
    const hasSameLabel = nodes.reduce((countSameLabel, _node) => {
      if (_node.data.label === `${count}-Inserir-título`) {
        return (countSameLabel += 1);
      }
      return countSameLabel;
    }, 0);
    if (hasSameLabel >= 1) {
      return checkSameName(count + 1);
    }
    return count;
  };

  const deleteNode = () => {
    dispatch(setVariablesIsUsedOnAnotherNodeModalOpen(true));
    // const targetValue = nodeSelected?.data?.userInteractions?.[0]?.target;

    // const entities: string[] = data.userInteractions.reduce((acc, el) => {
    //   if (el?.entityTypeName !== "") {
    //     return acc.concat(el.entityTypeName);
    //   }
    //   return acc;
    // }, []);
    // const entitiesAreBeingUsed = [];
    // if (entities?.length) {
    //   entities.map((entity) => {
    //     const entityIsUsedAsVariable = entityIsSetAsVariable(
    //       entity,
    //       nodeSelected?.id,
    //       nodes
    //     );
    //     if (entityIsUsedAsVariable?.length) {
    //       return entitiesAreBeingUsed.push({ entityIsUsedAsVariable });
    //     }
    //     return entity;
    //   });
    // }

    // if (entitiesAreBeingUsed?.length) {
    //   dispatch(setVariablesIsUsedOnAnotherNodeModalOpen(true));
    // } else if (targetValue !== "") {
    //   dispatch(setNodeRelationship(true));
    // } else {
    //   confirmDeleteNodesAndEdges();
    //   dispatch(setLevels(edges));
    // }
  };
  // const nodesIdsWithVariablesFromThisEntity = () => {
  //   const entityIsSetAsVariableInAnotherNodesLocal: string[] = [];
  //   nodes.map((_node) => {
  //     return _node?.data?.botInteractions.map((bi) => {
  //       if (bi.text !== "") {
  //         const biObject = JSON?.parse(bi?.text);
  //         return biObject
  //           ?.map((biObj: { children: any }) => {
  //             return biObj?.children
  //               ?.map(
  //                 (biChildren: { type: string; children: { text: any }[] }) => {
  //                   if (biChildren?.type === "mention") {
  //                     if (biChildren?.children[0]?.text === nodeSelected?.id) {
  //                       return entityIsSetAsVariableInAnotherNodesLocal.push(
  //                         _node?.id
  //                       );
  //                     } return undefined;
  //                   }
  //                 }
  //               )
  //               .filter(
  //                 (notUndefined: undefined) => notUndefined !== undefined
  //               );
  //           })
  //           .filter((notUndefined: undefined) => notUndefined !== undefined);
  //       } return undefined;
  //     });
  //   });
  //   return entityIsSetAsVariableInAnotherNodesLocal;
  // };
  const EraseEntityFromBotId = (
    entityIsSetAsVariableOnAnotherLocal,
    nodeId,
    EntityName
  ) => {
    // eslint-disable-next-line array-callback-return
    nodes.map((node) => {
      if (
        entityIsSetAsVariableOnAnotherLocal
          ?.map((el) => el.nodeId)
          .includes(node.id)
      ) {
        const oldNode = node;
        const newBI = node?.data.botInteractions.map((bi) => {
          if (bi.text !== "") {
            const parseBi: ISlateText[] = JSON.parse(bi?.text);
            return {
              ...bi,
              text: JSON.stringify(
                parseBi?.map((biParsed) => {
                  return {
                    ...biParsed,
                    children: biParsed.children.map((biChildren) => {
                      if (
                        biChildren.type === "mention" &&
                        biChildren.children &&
                        biChildren?.children[0]?.text === nodeId &&
                        biChildren?.variable === EntityName
                      ) {
                        return { text: "" };
                      }
                      return biChildren;
                    })
                  };
                })
              )
            };
          }
          return bi;
        });
        dispatch(
          updateRobotInteraction({
            id: newBI[0]?.id,
            nodeId: oldNode?.id,
            text: newBI[0]?.text
          })
        );
      }
    });
  };
  const RejectExclusion = () => {
    dispatch(setVariablesIsUsedOnAnotherNodeModalOpen(false));
  };

  const confirmDeleteNodesAndEdges = () => {
    const entities = nodeSelected.data.userInteractions.filter(
      (el) => el.entityTypeName?.length > 0
    );
    if (entities?.length > 0) {
      entities.map((el) => {
        const entityIsSetAsVariableOnAnotherLocal = entityIsSetAsVariable(
          el.entityTypeName,
          nodeSelected.id,
          nodes
        );
        if (entityIsSetAsVariableOnAnotherLocal?.length) {
          EraseEntityFromBotId(
            entityIsSetAsVariableOnAnotherLocal,
            nodeSelected.id,
            el.entityTypeName
          );
        }
      });
    }
    // eslint-disable-next-line no-use-before-define

    const getParents = edges
      ?.map((edge) => {
        if (edge?.target === nodeSelected?.id) {
          return edge?.source;
        }
      })
      .filter((notUndefineds) => notUndefineds !== undefined);
    nodes
      .filter((node) => getParents.includes(node.id))
      .map((node) =>
        node.data.userInteractions?.filter((ui) => {
          if (
            ui.target === nodeSelected?.id &&
            node.data.userInteractions.length > 1
          ) {
            dispatch(
              deleteUserInteraction({
                nodeId: ui.source as string,
                userInteractionId: ui?.id
              })
            );
            return ui;
          } else if (
            ui.target === rest.id &&
            node.data.userInteractions.length <= 1
          ) {
            const ObjectUpdateUserInteractions = {
              nodeId: node.id,
              userInteractionId: ui.id,
              id: ui.id,
              source: node.id,
              target: "",
              comparison: ui?.comparison,
              phrases: ui?.phrases,
              entityTypeId: ui?.entityTypeId,
              entityTypeName: ui?.entityTypeName
            };
            dispatch(updateUserInteraction(ObjectUpdateUserInteractions));
          } else {
            return ui;
          }
        })
      );

    const edgesToDelete = edges
      .map((edge) => {
        if (edge.id.includes(nodeSelected?.id)) {
          return {
            id: edge.id,
            type: "remove"
          };
        }
      })
      .filter((notUndefineds) => notUndefineds !== undefined);
    dispatch(onEdgesChange(edgesToDelete as EdgeChange[]));
    dispatch(changedPhoneComponent(true));
    dispatch(onNodesChange([{ id: nodeSelected?.id, type: "remove" }]));
    dispatch(setLevelUntiedNodes(edges));
    dispatch(setLevels(edges));
    dispatch(setVariablesIsUsedOnAnotherNodeModalOpen(false));
  };

  const AcceptExclusion = () => {
    const entities: string[] = data.userInteractions.reduce((acc, el) => {
      if (el?.entityTypeName !== "") {
        return acc.concat(el.entityTypeName);
      }
      return acc;
    }, []);
    if (entities?.length) {
      entities.map((entity) => {
        const entityIsUsedAsVariable = entityIsSetAsVariable(
          entity,
          nodeSelected?.id,
          nodes
        );
        if (entityIsUsedAsVariable?.length) {
          // eslint-disable-next-line array-callback-return
          nodes.map((node) => {
            if (
              entityIsUsedAsVariable.map((el) => el.nodeId).includes(node.id)
            ) {
              const oldNode = node;
              const newBI = node?.data.botInteractions.map((bi) => {
                if (bi.text !== "") {
                  const parseBi: ISlateText[] = JSON.parse(bi?.text);
                  return {
                    ...bi,
                    text: JSON.stringify(
                      parseBi?.map((biParsed) => {
                        return {
                          ...biParsed,
                          children: biParsed.children.map((biChildren) => {
                            if (
                              biChildren.type === "mention" &&
                              biChildren.children &&
                              biChildren?.children[0]?.text ===
                                nodeSelected?.id &&
                              biChildren?.variable === entity
                            ) {
                              return { text: "" };
                            }
                            return biChildren;
                          })
                        };
                      })
                    )
                  };
                }
                return bi;
              });
              dispatch(
                updateRobotInteraction({
                  id: newBI[0]?.id,
                  nodeId: oldNode?.id,
                  text: newBI[0]?.text
                })
              );
            }
          });
        }
        return entity;
      });
    }

    // eslint-disable-next-line array-callback-return

    confirmDeleteNodesAndEdges();
    dispatch(setLevels(edges));
  };
  const labels = nodes
    ?.map((node) => {
      return node?.data?.label;
    })
    .filter((notNulls) => notNulls !== null);

  const handleOnblur = () => {
    const counter = nodes?.reduce((count, _node) => {
      if (_node?.data?.label === data?.label) {
        return (count += 1);
      }
      return count;
    }, 0);
    if (counter > 1) {
      toastError?.current?.show({
        severity: "error",
        summary: "Node Inválido. Nome duplicado.",
        detail: "Favor escolher um outro nome para o node.",
        sticky: true
      });
    }
    if (data?.label.includes("_")) {
      toastError?.current?.show({
        severity: "error",
        summary: "Node com nome Inválido.",
        detail:
          'Nome do node não deve incluir o caractere "_" Favor escolher um outro nome para o node.',
        sticky: true
      });
    }
  };
  return (
    <div
      key={data.id}
      // onChange={data.onChange}
      // onMouseEnter={() => setShowButtons(true)}
      // onMouseLeave={() => setShowButtons(false)}
      style={{
        fontFamily: "Poppins, sans-serif",
        boxSizing: "border-box",
        padding: "10px",
        position: "relative",
        backgroundColor: "#FFF",
        borderRadius: "12px",
        WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        width: "196px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: showButtons ? "1px solid rgba(4, 106, 243, 1)" : "none"
      }}
    >
      <Dialog
        header="Atenção"
        visible={NodeIsUsedAsVariableModal}
        onHide={() => dispatch(setVariablesIsUsedOnAnotherNodeModalOpen(false))}
        draggable={false}
        style={{ height: "11rem", width: "30rem" }}
        resizable={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%"
          }}
        >
          <div className="flex ">
            <span className="pi pi-exclamation-triangle mr-2" />
            <p>Tem certeza que deseja excluir este node?</p>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              label="Sim"
              className="p-button-danger ml-2 mr-2"
              onClick={() => AcceptExclusion()}
            />
            <Button
              label="Não"
              className="p-button-info"
              onClick={RejectExclusion}
            />
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} />
      <Toast ref={toastError} position="top-left" />
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#777777", top: -6, position: "absolute" }}
        isConnectable={isConnectable}
      />
      {isTemplate ? (
        <div style={{ border: "none", margin: "0 auto" }} onBlur={handleOnblur}>
          {data.label}
        </div>
      ) : (
        <div>
          <input
            style={{ border: "none", margin: "0 auto", background: "white" }}
            type="text"
            disabled={isTemplate}
            value={data.label}
            onBlur={handleOnblur}
            onChange={(e) => {
              dispatch(
                changeLabel({
                  nodeId: rest.id,
                  label: e.target.value
                })
              );
              dispatch(changedPhoneComponent(true));
            }}
          />
        </div>
      )}
      {/* <div style={{ fontFamily: "Poppins, sans-serif" }}>Node padrão</div> */}
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ bottom: -6, background: "#777", position: "absolute" }}
        isConnectable={isConnectable}
      ></Handle>
      {isTemplate ? null : (
        <>
          <button
            type="button"
            style={{
              WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
              boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
              display: showButtons || data.selected ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              height: "25px",
              width: "25px",
              backgroundColor: "#FFF",
              color: "#FFF",
              position: "absolute",
              bottom: -10,
              right: -14
            }}
            onClick={addNodeDefault}
          >
            <img src={ButtonAdd} width="18px" alt="Add-Node" />
          </button>
          <button
            type="button"
            onClick={deleteNode}
            style={{
              WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
              boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
              display: showButtons || data.selected ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              height: "25px",
              width: "25px",
              backgroundColor: "#FFF",
              color: "#FFF",
              position: "absolute",
              top: -10,
              right: -14
            }}
          >
            <img src={ButtonDelete} width="10px" alt="Delete-Node" />
          </button>
        </>
      )}
    </div>
  );
});

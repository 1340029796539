import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import {
  bot,
  Bots,
  BotsActive,
  Chats,
  ChatsActive,
  Config,
  ConfigActive,
  Dashboard,
  DashboardActive,
  Entities,
  EntitiesActive,
  HomeIcon,
  HomeActive,
  Omini,
  OminiAtive,
  Users,
  UsersActive,
  SairGray,
  Template,
  TemplateAtive,
  Contacts,
  ContactsAtive,
  Campaign,
  CampaignAtive
} from "../../assets";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import "../comum_css/rawCss.css";
import { setSidebarChats, setTitleTab } from "../../redux/slices/sidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export default () => {
  const [expanded, setExpanded] = useState(false);
  const [openConfigMenu, setOpenConfigMenu] = useState(false);
  const toggleConfigMenu = () => {
    setOpenConfigMenu((previous) => !previous);
  };
  const { user, hasAnyPermission, logout } = useAuth() as AuthContextType;
  const routeName = useLocation().pathname;
  const [screenMobile, setScreenMobile] = useState(true);
  const dispatch = useAppDispatch();
  const sidebarChats = useAppSelector((state) => state.sidebar.sidebarChats);

  const iconUpDown = openConfigMenu ? (
    <MdKeyboardArrowUp />
  ) : (
    <MdKeyboardArrowDown />
  );

  useEffect(() => {
    dispatch(setTitleTab(user.plataform.displayName));
  }, [user]);

  const linkStyle = css`
    color: #544f77;
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    cursor: pointer;
  `;

  let isCurvatureDisabled = false;

  // eslint-disable-next-line func-style
  function isCurrentRoute(iconRoute: string) {
    // eslint-disable-next-line eqeqeq
    if (iconRoute == routeName) {
      return true;
    }
    return null;
  }

  const disableCurvature = () => {
    const linkWhereDisabled: Record<string, boolean> = {
      "/omnichannel": true
    };
    if (linkWhereDisabled[routeName as keyof typeof linkWhereDisabled]) {
      isCurvatureDisabled = true;
    }
  };

  const handleSair = () => {
    logout();
    dispatch(setSidebarChats(false));
  };

  disableCurvature();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!screenMobile ? (
        <div
          css={css`
            height: 100vh;
            margin-right: 80px;
          `}
        >
          <div
            style={{ zIndex: 10 }}
            className={`${expanded ? "fade-background" : ""}`}
          />

          <div
            style={{ zIndex: 10 }}
            className={`${expanded ? "tamanhoExpanded" : "tamanho"}`}
            onMouseOver={() => {
              setExpanded((previous) => !previous);
            }}
            onMouseOut={() => {
              setExpanded(false);
            }}
          >
            <div
              className="sidebarHeader"
              css={
                isCurvatureDisabled && !expanded
                  ? ""
                  : css`
                      border-top-right-radius: 20px;
                    `
              }
            >
              <img src={bot} />

              {expanded ? (
                <span
                  css={css`
                    color: white;
                    font-size: 40px;
                    font-weight: bold;
                  `}
                >
                  {user.plataform.displayName}
                </span>
              ) : null}
            </div>

            <div
              css={css`
                background-color: #02cdad;
                height: calc(100% - 65px);
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  border-top: 1px solid #d2d2d2;
                  border-right: 1px solid #d2d2d2;
                  border-top-right-radius: 20px;
                  background-color: #ffffff;
                `}
              >
                <div
                  css={css`
                    align-items: ${expanded ? "flex-start" : "center"};
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    gap: 25px;
                    padding: 20px;
                    overflow-y: auto;
                    overflow-x: hidden;
                  `}
                >
                  <Link className="hoverNavIcon" to="/home" css={linkStyle}>
                    {isCurrentRoute("/home") || isCurrentRoute("/") ? (
                      <img src={HomeActive} width={25} height={25} />
                    ) : (
                      <img src={HomeIcon} width={25} height={25} />
                    )}

                    {expanded ? "Home" : null}
                  </Link>

                  {user.admin || hasAnyPermission("dashboard_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/dashboard"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/dashboard") ? (
                        <img src={DashboardActive} width={25} height={25} />
                      ) : (
                        <img src={Dashboard} width={25} height={25} />
                      )}

                      {expanded ? "Dashboard" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("omnichannel_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/omnichannel"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/omnichannel") ? (
                        <img src={OminiAtive} width={25} height={25} />
                      ) : (
                        <img src={Omini} width={25} height={25} />
                      )}

                      {expanded ? "Omnichannel" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("dialog_view") ? (
                    <Link className="hoverNavIcon" to="/rooms/" css={linkStyle}>
                      {isCurrentRoute("/rooms/") ? (
                        <img src={ChatsActive} width={25} height={25} />
                      ) : (
                        <img src={Chats} width={25} height={25} />
                      )}

                      {expanded ? "Gestão de salas" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("entity_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/entities/"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/entities/") ? (
                        <img src={EntitiesActive} width={25} height={25} />
                      ) : (
                        <img src={Entities} width={25} height={25} />
                      )}

                      {expanded ? "Entidades" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("bot_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/chatbots"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/chatbots") ? (
                        <img src={BotsActive} width={25} height={30} />
                      ) : (
                        <img src={Bots} width={25} height={30} />
                      )}

                      {expanded ? "Meus robôs" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("template_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/templates-list/"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/templates-list/") ? (
                        <img src={TemplateAtive} width={25} height={25} />
                      ) : (
                        <img src={Template} width={25} height={25} />
                      )}
                      {expanded ? "Templates" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("user_view") ? (
                    <Link className="hoverNavIcon" to="/users/" css={linkStyle}>
                      {isCurrentRoute("/users/") ? (
                        <img src={UsersActive} width={25} height={25} />
                      ) : (
                        <img src={Users} width={25} height={25} />
                      )}

                      {expanded ? "Usuários" : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("contact_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/contacts"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/contacts") ? (
                        <img src={ContactsAtive} width={25} height={25} />
                      ) : (
                        <img src={Contacts} width={25} height={25} />
                      )}

                      {expanded ? "Contatos" : null}
                    </Link>
                  ) : null}

                  {user.allowCampaignCreation &&
                  hasAnyPermission("campaign_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/campaigns"
                      css={linkStyle}
                    >
                      {isCurrentRoute("/campaigns") ? (
                        <img src={CampaignAtive} width={25} height={25} />
                      ) : (
                        <img src={Campaign} width={25} height={25} />
                      )}

                      {expanded ? "Campanhas" : null}
                    </Link>
                  ) : null}

                  <div
                    css={css`
                      border: 1px solid #d2d2d2;
                      width: 100%;
                      margin-bottom: 0;
                    `}
                  />

                  <a
                    onClick={() => toggleConfigMenu()}
                    css={linkStyle}
                    className="colorWokspaceConfig"
                  >
                    {isCurrentRoute("/Workspaces/") ||
                    isCurrentRoute("/profiles") ||
                    isCurrentRoute("/gcredentials") ||
                    isCurrentRoute("/change-company") ? (
                      <img src={ConfigActive} width={25} height={25} />
                    ) : (
                      <img src={Config} width={25} height={25} />
                    )}

                    {expanded ? "Configurações" : null}

                    {expanded && iconUpDown}
                  </a>

                  {openConfigMenu && expanded && (
                    <div className="configContainer">
                      <ul
                        style={{
                          marginLeft: "4px",
                          position: "relative",
                          bottom: "10px"
                        }}
                      >
                        <li>
                          {user.admin || hasAnyPermission("workspace_view") ? (
                            <Link
                              to="/Workspaces/"
                              className="colorWokspaceConfig"
                            >
                              Workspace
                            </Link>
                          ) : null}
                        </li>

                        <li className="mt-2">
                          {user.admin || hasAnyPermission("profile_view") ? (
                            <Link
                              to="/profiles"
                              className="colorWokspaceConfig"
                            >
                              Permissionamento
                            </Link>
                          ) : null}
                        </li>

                        <li className="mt-2">
                          {user.admin || hasAnyPermission("credential_view") ? (
                            <Link
                              to="/gcredentials"
                              className="colorWokspaceConfig"
                            >
                              Credenciais
                            </Link>
                          ) : null}
                        </li>

                        {user.admin ? (
                          <li className="mt-2">
                            <Link
                              to="/change-company"
                              className="colorWokspaceConfig"
                            >
                              Trocar de empresa
                            </Link>
                          </li>
                        ) : null}

                        {user.admin ? (
                          <li className="mt-2">
                            <Link to="/company" className="colorWokspaceConfig">
                              Empresas
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}

                  <div onClick={handleSair} css={linkStyle}>
                    <img
                      src={SairGray}
                      alt="Person Icon"
                      width={25}
                      height={25}
                    />

                    {expanded ? "Sair" : null}
                  </div>
                </div>

                <div
                  css={css`
                    align-items: center;
                    color: #999999;
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;
                  `}
                >
                  © {user.plataform.displayName}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100vw;
            padding: 0.7rem;
            background: #ffffff;
            margin-top: 0.5rem;
            z-index: 10;
          `}
        >
          {user.admin || hasAnyPermission("omnichannel_view") ? (
            <Link
              to="/omnichannel"
              css={linkStyle}
              onClick={() => dispatch(setSidebarChats(true))}
            >
              {isCurrentRoute("/omnichannel") ? (
                <img src={OminiAtive} width={30} height={30} />
              ) : (
                <img src={Omini} width={30} height={30} />
              )}
            </Link>
          ) : null}

          {user.admin || hasAnyPermission("dialog_view") ? (
            <Link
              to="/rooms/"
              css={linkStyle}
              onClick={() => dispatch(setSidebarChats(false))}
            >
              {isCurrentRoute("/rooms/") ? (
                <img src={ChatsActive} width={30} height={30} />
              ) : (
                <img src={Chats} width={30} height={30} />
              )}
            </Link>
          ) : null}

          <div onClick={handleSair} css={linkStyle}>
            <img src={SairGray} alt="Person Icon" width={30} height={30} />

            {expanded ? "Sair" : null}
          </div>
        </div>
      )}
    </>
  );
};
